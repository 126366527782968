var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"CPZuvpiTgzIcb3TI4QS6y"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { extraErrorDataIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'
import {
  SENTRY_ERROR_SAMPLE_RATE,
  SENTRY_REPLAY_SAMPLE_RATE,
  SENTRY_TRACE_SAMPLE_RATE,
} from '@/constants/config/sentry'

const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT
const SENTRY_DNS = process.env.NEXT_PUBLIC_SENTRY_DNS

/**
 * Integrations for the client side Sentry API available in the browser context.
 *
 * @name SentryBrowserIntegrations
 * @memberof Observability
 * @type {SentryBrowserIntegrations}
 */
const clientIntegrations = [
  new Sentry.Replay({
    maskAllText: true,
    blockAllMedia: true,
  }),
  new Sentry.BrowserTracing(),
  Object(extraErrorDataIntegration()),
]

/**
 * The config you add here will be used whenever a users loads a page in their browser.
 *
 * @name SentryBrowserOptions
 * @memberof Observability
 * @type {SentryBrowserOptions}
 */
const clientConfig = {
  debug: false,
  dsn: SENTRY_ENV ? SENTRY_DNS : undefined,
  environment: SENTRY_ENV,
  integrations: clientIntegrations,
  replaysSessionSampleRate: SENTRY_REPLAY_SAMPLE_RATE,
  replaysOnErrorSampleRate: SENTRY_ERROR_SAMPLE_RATE,
  sendDefaultPii: true,
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
}

Sentry.init(clientConfig)

const bydbSelectStyles = {
  gridColumnStart: '1',
  gridColumnEnd: '3',
  label: {
    color: 'white',
    fontFamily: 'bold',
    backgroundColor: '#191A19',
    pb: '8px',
  },
  '[class$="placeholder"]': { color: 'grey', fontFamily: 'regular' },
  'span+div': {
    borderColor: 'inherit',
    borderRadius: '30px',
  }, // workaround to style the dropdown
  ['.error-select']: { borderColor: 'error' },
}

const greatestHitsSelectStyles = {
  gridColumnStart: '1',
  gridColumnEnd: '3',
  color: 'textDark',
  label: {
    color: 'textDark',
    fontFamily: 'regular',
    fontSize: '17px',
    pb: '9px',
  },
  '[class$="placeholder"]': {
    color: 'gray',
  },
  '[class^="error-select"]': {
    '[class$="control"]': {
      border: '1px solid',
    },
  },
  'span+div': {
    borderColor: 'textDark',
    borderRadius: '0',
  },
  div: {
    span: {
      display: 'none',
    },
    div: {
      svg: {
        fill: 'textDark',
      },
    },
  },
}

export const buildYourDreamBagForms = {
  container: {
    contact: {
      display: 'grid',
      rowGap: '15px',
      columnGap: '20px',
      width: '100%',
      color: 'black',
      label: {
        color: 'white',
      },
    },
    survey: {
      display: 'grid',
      rowGap: '15px',
      columnGap: '20px',
    },
  },
  inputContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    label: {
      color: 'white',
      fontFamily: 'bold',
      backgroundColor: '#191A19',
      pb: '8px',
    },
    ['.error-input']: {
      borderColor: 'error',
    },
  },
  input: {
    background: 'white',
    borderRadius: '30px',
    '--theme-ui-input-autofill': {
      backgroundColor: 'white',
    },

    ':-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 30px #fff inset !important',
      '-webkit-text-fill-color': '#black !important',
    },
    ':-webkit-autofill:hover': {
      '-webkit-box-shadow': '0 0 0 30px #FFF inset !important',
    },
    ':-webkit-autofill:focus': {
      '-webkit-box-shadow': '0 0 0 30px #fff inset !important',
      '-webkit-text-fill-color': '#black !important',
    },
    ':-webkit-autofill:active': {
      '-webkit-box-shadow': '0 0 0 30px #fff inset !important',
    },
  },
  errorMessage: {
    color: 'error',
    marginTop: '9px',
    fontStyle: 'italic',
  },
  countryContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    label: {
      color: 'white',
      fontFamily: 'bold',
      backgroundColor: '#191A19',
      pb: '8px',
    },
    '[class$="placeholder"]': { color: 'gray', fontFamily: 'regular' },
    'span+div': { borderRadius: '30px' }, // workaround to style the dropdown
  },
  countyContainer: bydbSelectStyles,
  countrySubdivisionContainer: bydbSelectStyles,
  selectContainer: bydbSelectStyles,
  select: {
    control: { borderRadius: 'none' }, //styling border not working
    placeholder: { fontFamily: 'Roboto-Light', color: 'primary' },
  },
  phoneContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
  },
  checkboxContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
  },
  button: {
    background: 'primary',
    width: '50%',
  },
  checkbox: {
    div: {
      label: {
        div: {
          'input:checked~svg': {
            color: 'secondary',
          },
          'input~svg': {
            color: 'secondary',
          },
          p: {
            fontFamily: 'light',
            fontSize: '16px',
            lineHeight: '22px',
            a: { fontFamily: 'regular', color: 'primary' },
          },
        },
      },
    },
  },
  submitContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    display: 'flex',
    justifyContent: 'center',
    width: ['100%', '97%'],
    button: {
      background: 'primary',
      color: 'textDark',
      fontSize: '18px',
      lineHeight: '18px',
      mt: '10px',
      mb: '30px',
      mx: '0px',
      p: '15px 60px',
      borderRadius: '77px',
      '&:hover': {
        color: 'textLight',
        background: 'secondary',
      },
      marginBottom: '75px',
    },
  },
}

export const greatestHitsForms = {
  container: {
    contact: {
      justifyContent: 'center',
      display: 'grid',
      rowGap: '17px',
      width: '100%',
    },
    survey: {
      justifyContent: 'center',
      display: 'grid',
      rowGap: '17px',
      width: '100%',
    },
  },
  inputContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    color: 'textDark',
    label: {
      fontSize: '17px',
      pb: '9px',
    },
  },
  input: {
    height: '40px',
    background: 'background',
    border: '1px solid',
    borderColor: 'textDark',
    borderRadius: '0',
    p: '10px',
    '--theme-ui-input-autofill': {
      backgroundColor: 'background',
    },
    ':-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 30px #fff inset !important',
      WebkitTextFillColor: 'textDark !important',
    },
    ':-webkit-autofill:hover': {
      WebkitBoxShadow: '0 0 0 30px #FFF inset !important',
    },
    ':-webkit-autofill:focus': {
      WebkitBoxShadow: '0 0 0 30px #fff inset !important',
      WebkitTextFillColor: 'textDark !important',
    },
    ':-webkit-autofill:active': {
      WebkitBoxShadow: '0 0 0 30px #fff inset !important',
    },
  },
  errorMessage: {
    color: 'error',
    marginTop: '9px',
    fontSize: '10px',
  },
  countryContainer: greatestHitsSelectStyles,
  countrySubdivisionContainer: greatestHitsSelectStyles,
  countyContainer: greatestHitsSelectStyles,
  selectContainer: greatestHitsSelectStyles,
  checkboxContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    div: {
      '.error-message': { marginLeft: '32px' },
      label: {
        div: {
          'input:checked~svg': {
            fill: 'textDark',
          },
          'input~svg': {
            fill: 'textDark',
          },
          p: {
            color: 'textDark',
            fontFamily: 'regular',
            fontSize: ['12px', '14px'],
            lineHeight: '18px',
            a: {
              color: '#DFA511',
              textDecoration: 'underline'
            },
          },
        },
      },
    },
  },
  multipleCheckboxesContainer: {
    fontSize: '17px',
    color: 'textDark',
  },
  multipleCheckboxes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    mt: '9px',
  },
  submitContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    display: 'flex',
    justifyContent: 'center',
    button: {
      width: ['100%', '354px'],
      height: '50px',
      background: 'primary',
      border: '1px solid',
      borderColor: 'primary',
      color: 'textLight',
      fontFamily: 'bold',
      fontSize: '20px',
      lineHeight: '24px',
      '&:hover': {
        color: 'primary',
        background: 'background',
      },
    },
  },
}

export const buildYourDreamBagColors = {
  primary: '#F5A800',
  secondary: '#003DA5',
  textDark: '#000000',
  textLight: '#FFFFFF',
  error: '#FF0000',
  background: '#000000',
  disabled: '#97A3AD'
}

export const greatestHitsColors = {
  primary: '#000000',
  secondary: '#003DA5',
  textDark: '#000000',
  textLight: '#FFFFFF',
  error: '#FF0000',
  background: '#FFFFFF',
  disabled: '#97A3AD'
}

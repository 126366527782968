import box from './box'
import { buildYourDreamBagButtons, greatestHitsButtons } from './buttons'
import { buildYourDreamBagColors, greatestHitsColors } from './colors'
import fontWeights from './fontWeights'
import fonts from './fonts'
import { buildYourDreamBagForms, greatestHitsForms } from './forms'
import grids from './grids'
import images from './images'
import input from './input'
import layout from './layout'
import label from './label'
import links from './links'
import sizes from './size'
import select from './select'
import { buildYourDreamBagText, greatesHitsText } from './text'
import icons from './icons'
import sliders from './sliders'
import styles from './styles'
import breakpoints from './breakpoints'

const baseTheme = {
  fonts,
  fontWeights,
  input,
  links,
  images,
  sizes,
  layout,
  label,
  box,
  grids,
  icons,
  sliders,
  styles,
  select,
  breakpoints
}

export const buildYourDreamBagTheme = {
  ...baseTheme,
  buttons: buildYourDreamBagButtons,
  colors: buildYourDreamBagColors,
  forms: buildYourDreamBagForms,
  text: buildYourDreamBagText
}

export const greatestHitsTheme = {
  ...baseTheme,
  buttons: greatestHitsButtons,
  colors: greatestHitsColors,
  forms: greatestHitsForms,
  text: greatesHitsText
}

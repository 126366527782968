export const buildYourDreamBagButtons = {
  primary: {
    background: 'primary',
    color: 'textDark',
    width: '30%',
    gridColumnStart: '1',
    gridColumnEnd: '3',
    justifySelf: 'center',
    fontFamily: 'bold',
    fontSize: '18px',
    mt: '20px',
    p: '15px 30px',
    mb: '5%',
    borderRadius: '0px',
    '&:hover': {
      bg: 'secondary',
      color: 'textLight'
    },
    '&:disabled': {
      bg: 'gray',
      opacity: '0.5',
    },
  },
  homeButton: {
    color: 'white',
    width: ['90%', '400px'],
    minWidth: ['200px', '400px'],
    fontSize: ['18px', '24px'],
    fontFamily: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    mt: ['20px', '30px'],
    mb: ['30px', '20px'],
    background: 'primary',
    borderRadius: '30px',
  },
  submitContainer: {
    variant: 'text.mediumBold',
    display: 'flex',
    justifyContent: 'center',
    width: ['100%', '100%', '400px'],
    minWidth: ['200px', '200px', '400px'],
    borderRadius: '56.25px',

    '&:hover': {
      color: 'textLight',
      background: 'secondary'
    },

    button: {
      background: 'primary',
      lineHeight: '18px',
      mx: '0px',
      p: '12px 60px',
    },
  },
}

export const greatestHitsButtons = {
  ...buildYourDreamBagButtons // TODO: to be styled with TLM-129, when we need to create the carousel with buttons
}
